import React from 'react'

import Layout from '../../components/Layout'
import JobRoll from '../../components/JobRoll'

export default class JobIndexPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hasPosts: true
    }

    this.doesNotHavePosts = this.doesNotHavePosts.bind(this)
  }

  doesNotHavePosts () {
    this.setState({
      hasPosts: false
    })
  }

  render () {
    let hasPosts = this.state.hasPosts

    return (
      <Layout>
        {hasPosts && <div
          className='full-width-image-container margin-top-0'
          style={{
            backgroundImage: `url('/img/undraw_add_notes.svg')`,
            backgroundSize: `contain`,
            backgroundRepeat: `no-repeat`
          }}
        >
          <h1
            className='has-text-weight-bold is-size-1'
            style={{
              backgroundColor: '#3407B9',
              color: 'white',
              padding: '1rem'
            }}
          >
            Job posts
          </h1>
        </div>}
        <section className='section'
          style={{
            background: '#F8F8FA'
          }}>
          <div className='container'>
            <div className='content'>
              <JobRoll doesNotHavePosts={this.doesNotHavePosts} />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
