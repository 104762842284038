import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class JobRoll extends React.Component {
  constructor (props) {
    super(props)

    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    if (posts.length < 1) {
      props.doesNotHavePosts()
    }
  }
  render () {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div>
        {
          posts.length < 1 &&
          <div style={{
            marginTop: '50px',
            textAlign: 'center',
            fontSize: '3rem',
            fontWeight: '700',
            color: '#3F3D56',
            fontFamily: "'Raleway', 'sans-serif'"
          }}>
            Job posts coming soon!
            <p style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '700',
              color: '#3F3D56',
              fontFamily: "'Raleway', 'sans-serif'"
            }}>Want to post a job?<AniLink paintDrip to='/contact'> Contact Us</AniLink></p>
            <img src='/img/coming-soon.png' />
          </div>
        }

        <div className='columns is-multiline' >
          {posts &&
            posts.map(({ node: post }) => (
              <div className='is-parent column is-6' key={post.id}>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                  style={{
                    background: 'white',
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: '25px'
                  }}
                >
                  <header>
                    {post.frontmatter.featuredimage ? (
                      <div className='featured-thumbnail'>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${
                              post.title
                            }`
                          }}
                        />
                      </div>
                    ) : null}
                    <p className='post-meta'>
                      <Link
                        className='title is-size-4'
                        to={post.fields.slug}
                        style={{
                          color: '#3F3C57 !important',
                          fontWeight: '700',
                          fontFamily: "'Raleway', 'sans-serif'",
                          textDecoration: 'none'
                        }}
                      >
                        {post.frontmatter.title}
                      </Link>
                      <span> &bull; </span>
                      <span className='subtitle is-size-5 is-block'>
                        {post.frontmatter.date}
                      </span>
                    </p>
                  </header>
                  <p>
                    {post.excerpt}
                    <br />
                    <br />
                    <Link className='button'
                      to={post.fields.slug}
                      style={{
                        color: '#FFF',
                        background: '#3407B9',
                        boxShadow: '0px 4px 30px rgba(51, 0, 188, 0.2)'
                      }}>
                      Read more →
                    </Link>
                  </p>
                </article>
              </div>
            ))
          }
        </div>
      </div >
    )
  }
}

JobRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  }),
  doesNotHavePosts: PropTypes.func
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query JobRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "job-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => {
      return (<JobRoll data={data} count={count} doesNotHavePosts={props.doesNotHavePosts} />)
    }}
  />
)
